import React,{ useState, useEffect } from 'react';
import { StyleSheet, View, Text, Modal, ActivityIndicator, ImageBackground, TouchableOpacity } from 'react-native';
import { Icon, Button } from 'react-native-elements'
import QRCode from "react-qr-code";
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm'
import { getTour, analytics, updateTour, auth } from '../helpers/fbHelper'
import success from "../../assets/success.gif"
import referralImg from "../../assets/referral.svg"
import confirm from "../../assets/confirm.png"
import { Checkmark } from 'react-checkmark'
import {Menu, MenuOptions, MenuOption, MenuTrigger, renderers } from 'react-native-popup-menu';
import { logEvent } from "firebase/analytics"
import { signInAnonymously, signOut, deleteUser } from "firebase/auth";

const QrReferral = ({ navigation, screenName }) =>{
  const url = new URLSearchParams(window.location.search);
  const id = url.get('tourId') ??  'QMqjalo7V3BXT8AefKgK'; //'VJAQ4m96wcaCulr1dIfq' 
  const tour = getTour(id)
  const mutation = updateTour(id)
  const index = url.get('stopId') ?? 0
  const refId = url.get('refId') ?? "7566a2b4-1fb0-4fd3-b601-733a9ceebfb1"
  var [showSuccess,setSuccess] = useState(true)
  var [redeemed,setRedeemed] = useState(false)
  var [loading,setLoading] = useState(false)
  var [showQuestions,setQuestions] = useState(false)
  var [showContact,setContact] = useState({ open: false, contact: 'guide'})

  useEffect(() => {
    if(!tour.isLoading) {
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 1500)
    }
  }, [tour.isLoading])

  useEffect(() => {
    if(showContact.open) {
      logEvent(analytics, "coupon_questions_press", { tourId: id, stopId: index, id: refId, contactOpened: showContact.contact });
    }
  }, [showContact.open])

  if(tour.isLoading) {
    return (
      <View style={styles.container}>
      </View>
    );
  }

  if(showSuccess) {
    return (
      <View style={{...styles.container, justifyContent: 'center'}}>
        <Checkmark size="170px"/>
      </View>
    )
  }

  const redeemPress = async () => {

    const snapshot = tour.data
    var { Stops: stops } = snapshot.data() ?? {}
    var eventData = {}

    setLoading(true)
    var { user } = await signInAnonymously(auth)

    var media = stops[index].media ?? []
    for (var i = 0; i < media.length; i++) {
      if(media[i].type === 'referral') {
        var content = media[i].content
        for (var j = 0; j < content.length; j++) {
          if(content[j].id === refId) {
            stops[index].media[i].content[j].redeems++
            eventData = stops[index].media[i].content[j]
            logEvent(analytics, "coupon_redeem", { tourId: id, stopId: index, ...eventData });
            mutation.mutate({ Stops: stops })
            break
          }
        }
        break
      }
    } 

    user.delete()
    setLoading(false)
    setRedeemed(true)
  }

  const renderContactModal = (details, pic, name) => {
    const contact = showContact.contact
    var { fname, lname, phoneNum } = details[contact] 

    const title = { 
      guide: 'Guide',
      business: 'Business'
    }

    return (
      <div style={styles.contact}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <span style={styles.contactTitle}>{title[contact] + ' contact details'}</span>
          <Icon onPress={() => setContact({ ...showContact, open: false })} size={20} name="close" type="antdesign" color="gray"/>
        </div>
        {
          contact === 'guide' && (
            <div style={{display: 'flex', alignItems: 'center', paddingTop: 15, marginBottom: 10}}> 
              <img src={guideProfilePic} style={{width: 50, height: 50, borderRadius: '50%', marginRight: 30 }} />
              <span style={styles.contactTitle}>{name}</span>
            </div>
          )
        }
        <div style={{display: 'flex', paddingTop: 10 }}>
          <div style={{display: 'flex', flexDirection: 'column', width: '15%', paddingRight: 10}}>
            <Icon style={{marginBottom: 27}} size={32} color="#60BE8C" name="idcard" type="antdesign" />
            <Icon  size={32} color="#60BE8C" name="phone" type="material-community" />
          </div>
          <div  style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
            <span style={styles.contactTitle}>First Name</span>
            <span style={{...styles.contactText, marginBottom: 30}}>{fname}</span>
            <TouchableOpacity onPress={() => window.open('tel:' + phoneNum)}>
              <span style={styles.contactTitle}>Phone Number</span>
              <span style={styles.contactText}>{phoneNum}</span>
            </TouchableOpacity>
          </div>
          <div  style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
            <span style={styles.contactTitle}>Last Name</span>
            <span style={styles.contactText}>{lname}</span>
          </div>
        </div>        
      </div>
    )
  }

  const snapshot = tour.data;
  const { Name: name, Stops: stops, Guide: guide, Owner: owner, persona, guideProfilePic } = snapshot.data() ?? ''
  var stop = stops[index] ?? { media: [] }
  var coupons = stop.media.filter(val => val.type === 'referral')[0]
  var redeemButton = !redeemed ? styles.redeem : {...styles.redeem, backgroundColor: 'white'}
  var redeemIcon = redeemed ? { name: "check-decagram", type: 'material-community', color: '#4ECB71', size: 20 } : null

  var coupon = coupons.content.filter(val => val.id === refId)[0]
  var { subtitle, description, redeems, discount, type } = coupon

  logEvent(analytics, "coupon_scanned", { tourId: id, stopId: index, ...coupon });

  var options = { 
    bogof: { subtitle: '2 for 1', description: "With each purchase receive a second for free"}, 
    discount: { subtitle: discount + '% off any purchase coupon', description: "Enjoy a substantial " + discount + '% discount' }, 
    sample: { subtitle: 'Free sample with this coupon', description: 'Try before you buy with a free sample'}, 
    special: { subtitle: subtitle, description: description },
    custom: { subtitle: subtitle, description: description } 
  }

  subtitle = options[type].subtitle
  description = options[type].description.replaceAll(/\n/g, '  \n')

  const contactOptions = ["Contact Guide", "Contact Business"]
  const contactInfo = coupons.contact ?? { guide: { fname: 'n/a', lname: 'n/a', phoneNum: ''}, business: { fname: 'n/a', lname: 'n/a', phoneNum: ''} }

  return (
    <View style={styles.container}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showContact.open}
        onRequestClose={() => {
          setContact({ ...showContact, open: false});
        }}>
        <div onClick={() => setContact({ ...showContact, open: false})} style={{display: 'flex', height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,0.5)', justifyContent: 'center', alignItems: 'center' }}>
          { renderContactModal(contactInfo, guideProfilePic, guide)}
        </div>
      </Modal>
      <ImageBackground source={referralImg} resizeMode="contain" style={styles.popup}>
        <span style={{fontSize: 25, fontFamily: 'quicksand_bold'}}>Coupon Redemption</span>
        <p>This person was sent by</p>
        <img src={guideProfilePic} style={{width: 100, height: 100, borderRadius: '50%'  }} />
        <span style={{fontSize: 20, fontFamily: 'quicksand_bold', width: '70%'}}>{guide}</span>
        <p>They are entitled to:</p>
        <div style={{height: 40, width: '60%', fontFamily: 'quicksand_bold', paddingBottom: 5}}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{subtitle}</ReactMarkdown>
          <div style={{fontSize: 12, fontFamily: 'Quicksand'}}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{description}</ReactMarkdown>
          </div>
        </div>
        <div style={styles.bottom}>
          <Button loading={loading} containerStyle={redeemButton} titleStyle={{fontFamily: 'quicksand_bold', color: !redeemed ? 'white' : 'black'}} 
                  buttonStyle={{ backgroundColor: !redeemed ? '#60BE8C': 'white' }} title={!redeemed ? "Redeem Coupon" : "Redeemed"} 
                  onPress={redeemPress}
                  icon={redeemIcon} iconRight>
          </Button>
          <Menu renderer={renderers.Popover} rendererProps={{ placement: 'bottom' }} >
            <MenuTrigger text='Questions?'customStyles={{ triggerWrapper: styles.questions, triggerText: { fontFamily: 'quicksand_bold', fontSize: 16 } }} />
            <MenuOptions customStyles={{optionsContainer: { borderRadius: 10 } }} > 
              <MenuOption customStyles={{ optionWrapper: { ...styles.contactWrapper  }, optionText: { fontSize: 14 } }} 
                onSelect={() => setContact({ contact: 'guide', open: true})} text='Contact Guide' />
              <MenuOption customStyles={{ optionWrapper: { ...styles.contactWrapper }, optionText: { fontSize: 14 } }} 
                onSelect={() => setContact({ contact: 'business', open: true})} text='Contact Business'/>
            </MenuOptions>
          </Menu>
        </div>
        <p style={{fontSize: 11, width: '85%', color: 'gray'}}>This coupon has been redeemed <b style={{fontFamily: 'Quicksand'}}>{redeems ?? 0}</b> times previously</p>
      </ImageBackground>
    </View>
  )
}

export default QrReferral

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: '15%',
    backgroundColor: '#fcfefc'
  },
  title: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'quicksand_bold',
    fontSize: 17
  },
  sheetContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    height: 300
  },
  video: {
    width: '100%',
    paddingBottom: 10
  },
  redeem: {
    borderRadius: 10,
    boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
    fontFamily: 'quicksand_bold',
  },
  questions: {
    borderRadius: 10,
    boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
    height: 35,
    justifyContent: 'center'
  },
  popup: {
    height: 600,
    width: '100%',
    paddingTop: '10%',
    alignItems: 'center',
    textAlign: 'center'
  },
  contact: {
    width: '70%', 
    padding: 20, 
    backgroundColor: 'white', 
    borderRadius: 25, 
    boxShadow: '0px 10px 40px gray'    
  },
  contactTitle: {
    fontSize: 14, 
    fontFamily: 'quicksand_bold'
  },
  contactText: {
    fontSize: 13, 
    color: 'gray', 
    fontFamily: 'Quicksand'
  },
  bottom: {
    display: 'flex', 
    height: '16%', 
    width: '60%', 
    flexDirection: 'column', 
    marginTop: '25%', 
    justifyContent: 'space-between'    
  },
  contactOptions: {
    top: '50%',
    width: '70%', 
    alignSelf: 'center', 
    marginLeft: '15%'
  },
  contactWrapper: { 
    width: 230, 
    borderBottom: "1px solid rgba(0,0,0,0.1)", 
    height: 30, 
    alignItems: 'center'
  }
});
