import React,{ useState, useEffect } from 'react';
import { StyleSheet, View, Text, Button, ActivityIndicator } from 'react-native';
import QRCode from "react-qr-code";
import { getTour, analytics } from '../helpers/fbHelper'
import success from "../../assets/success.gif"
import Referral from "../components/utils/referral"
import {createComponent} from '@lit-labs/react';
import { PlaceOverview as PlaceOverviewWC } from '../components/place_overview/place_overview.js';
import { Marker } from '@react-google-maps/api';
import { GoogleMap, useJsApiLoader  } from '@react-google-maps/api';

const PlaceOverview = createComponent({
  tagName: 'gmpx-place-overview',
  elementClass: PlaceOverviewWC,
  react: React,
});

const PlaceCard = ({ navigation, screenName }) =>{
  const url = new URLSearchParams(window.location.search);
  const id = url.get('placeId') ?? 'ChIJk8CcRwivEmsROPWHqq2Qlv0'; //'VJAQ4m96wcaCulr1dIfq' 
  const directions = url.get('url') ?? 'https://google.com'
  const iframe = window.frameElement 
  var [details, setDetails] = useState({  
    insight: url.get('insight') ?? null,
    brand: '#' + url.get('brandColor') ?? '60BE83',
    textColor: '#' + url.get('textColor') ?? 'FFFFFF'
  }) 


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyB7fofXXxvV2vcvADFR398kS9BzTu-7usU",
    version: "beta"
  })

  if(!isLoaded) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large"/>
      </View>
    );
  }


  return (
    <View style={styles.container}>
      <View style={{width: '90%', height: '95%', backgroundColor: 'white', justifyContent: 'center', display: 'flex' }}>
        <PlaceOverview
          size="custom"
          //place="ChIJk8CcRwivEmsROPWHqq2Qlv0"
          //travelMode="walking"
          //travelOrigin={{ lat: -33.87019342966326, lng: 151.196967743627 }}
          place={id}
          google-logo-already-displayed
        />
        { 
          details?.insight && (
            <Text style={{...styles.text, marginLeft: 20, marginTop: -20}}>{details.insight}</Text>
          )
        }
        <View style={{display: 'flex', flexDirection: 'row', marginTop: details?.insight ? 0  : -30}}>
          <a
            style={{...styles.linkContainer, backgroundColor: details?.brand }}
            href={"https://www.google.com/maps/search/?api=1&query=Sydney&query_place_id=" + id}
            target="_blank">
            <Text style={{...styles.linkText, color: details?.textColor }}>
              Directions
            </Text>
          </a>
          {
            details?.actionValue && (
              <a
                style={{...styles.linkContainer, backgroundColor: 'white', border: '1px solid lightgray'}}
                href={ details.actionValue }
                target="_blank">
                <Text style={{...styles.linkText, color: 'gray'}}>
                  { details.actionTitle }
                </Text>
              </a>
            )
          }
        </View>
      </View>
    </View>
  )
}

export default PlaceCard

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center', 
  },
  title: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'quicksand_bold',
    fontSize: 17
  },
  sheetContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    height: 300
  },
  video: {
    width: '100%',
    paddingBottom: 10
  },
  linkContainer: {
    marginTop: 10,
    marginBottom: 10,
    width: "50%",
    minHeight: 40,
    marginLeft: 20,
    backgroundColor: "#60be83",
    borderRadius: 5,
    minHeight: 40,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textDecorationLine: "none",
    paddingHorizontal: 10,
  },
  text: {
    color: '#757575',
    fontFamily: 'Quicksand',
    fontStyle: 'italic',
    fontSize: 14
  },
  linkText: {
    color: "white",
    fontSize: 12,
    textDecorationLine: "none",
    textAlign: "center",
    fontFamily: 'quicksand_bold'
  },
});
