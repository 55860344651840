import React, { useState } from 'react';
import { View, Button, Modal, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { Icon } from 'react-native-elements'
import { toast, ToastContainer } from 'react-toastify';
import { logEvent } from "firebase/analytics"

import { analytics } from '../helpers/fbHelper'
import "react-toastify/dist/ReactToastify.css";

const MenuOption = (props) => {
  const { type, currentType, onPress } = props 
  const color = currentType === type ? "#1F8FFF" : "grey"
  const title = {'roadmap': 'Default', 'satellite': 'Satellite', 'hybrid': 'Hybrid'}
  const border = currentType === type ? styles.mapSelected : {}
  return (
    <TouchableOpacity onPress={onPress}>
      <img
        src={require("../../assets/map_types/" + type + ".png")}
        style={{...styles.mapIcon, ...border}}
      />
      <Text style={{...styles.optionsText, color: color}}>{title[type]}</Text>
    </TouchableOpacity>
  )
}

const DropdownMenu = (props) => {
  const { updateMapType, tourId, category = "" } = props
  const [mapType,setMapType] = useState(category === 'Other' ? 'hybrid' : 'roadmap')
  const [visible,setVisible] = useState(false)

  if(!visible) return (
    <Icon onPress={() => setVisible(true)} raised size={18} color="#868E9B" 
          name="layers" type="material-icons" />
  ) 

  const changeMapType = (type) => {
    setMapType(type)
    const mapStyles = {
      roadmap: 'mapbox://styles/mapbox/streets-v12',
      satellite: 'mapbox://styles/mapbox/satellite-v9',
      hybrid: 'mapbox://styles/mapbox/satellite-streets-v12'
    }
    updateMapType(mapStyles[type])
  }

  const shareTour = () => {
    const url = window.location.href
    navigator.clipboard.writeText(url)

   logEvent(analytics, "press_share_tour", {
      tourId: tourId,
      selfGuided: false,
      isPaid: false
    });

    toast.success('Copied share link to clipboard', {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 1,
      theme: "light",
    });
  }

  return (
    <View style={styles.container}>
      <ToastContainer />
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <Text style={styles.title}>Map Type</Text>
        <Icon name="close" color="gray" onPress={() => setVisible(false)} />
      </View>
      <View style={styles.menuContainer}>
        <MenuOption currentType={mapType} onPress={() => changeMapType('roadmap')} type='roadmap'/>
        <MenuOption currentType={mapType} onPress={() => changeMapType('satellite')} type='satellite'/>
        <MenuOption currentType={mapType} onPress={() => changeMapType('hybrid')} type='hybrid'/>
      </View>
      <Text style={styles.title}>Options</Text>
      <TouchableOpacity onPress={shareTour} style={styles.options}>
        <Icon style={{paddingRight: 10}} name="share" type="simple-line-icon" size={20} color="black" />
        <Text style={styles.title}>Share</Text>
      </TouchableOpacity  >
    </View>
  )
}

export default DropdownMenu

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: 'white',
    justifyContent: 'space-evenly', 
    right: '80%',
    width: 200,
    height: 200,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: "#F8F8FA",
    border: '1px solid lightgray'
  },
  title: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'quicksand_bold',
    fontSize: 17
  },
  menuContainer: {
    justifyContent: 'space-evenly',
    width: '100%',
    flexDirection: 'row',
  },
  mapIcon: {
    width: 45,
    height: 45,
  },
  mapSelected: {
    borderRadius: 5,
    border: '2px solid #1F8FFF'
  },
  options: {
    width: '90%',
    padding: 5,
    flexDirection: 'row',
    borderRadius: 5, 
    backgroundColor: 'white',

  },
  optionsText: {
    fontFamily: "quicksand_bold",
    fontSize: 14,
    color: "black",
  },
});
