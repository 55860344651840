import React, { useState } from 'react';
import { View, Button, ImageBackground, Modal, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { Icon } from 'react-native-elements'
import { toast, ToastContainer } from 'react-toastify';
import { logEvent } from "firebase/analytics"
import QRCode from "react-qr-code";

import "react-toastify/dist/ReactToastify.css";


const DesktopModal = (props) => {
	const [visible,setVisible] = useState(true)
  const image = require("../../assets/desktop.png")
  const bkg = require("../../assets/background.png")

  const goBack = () => {
    var guideUrl = 'https://freeguides.com/experiences/' + props.id
    window.location.href = guideUrl
  }

  return ( 
    <View style={styles.container}>
      <ImageBackground source={bkg} blurRadius={7} resizeMode="cover" style={styles.background}/>
      <View style={styles.popup}>
        <img resizeMode="cover" src={image} style={styles.image}/>
        <View style={styles.close}>
          <Icon onPress={goBack} size={40} color="#868E9B" name="close" type="antdesign" />
        </View>
        <View style={styles.content}>
          <Text style={{...styles.text, fontFamily: 'quicksand_bold'}}>Explore on Mobile</Text>
          <Text style={styles.text}>anywhere, anytime</Text>
          <View style={styles.qrContainer}>
            <View style={styles.qr}>
              <QRCode
              size={256}
              style={{ flex: 1 }}
              value={window.location.href}
              title="Walking Tour"
              />
            </View>
            <Text style={{...styles.text, color: 'white'}}>SCAN ME</Text>
          </View>
        </View>
      </View>
      <View style={styles.overlay}/>
    </View>
  ) 
}

export default DesktopModal

const styles = StyleSheet.create({ 
	container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  background: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2
  },
  popup: {
    position: 'absolute',
    width: '60%',
    aspectRatio: 3.2 / 2,
    borderRadius: 20,
    zIndex: 3
  },
  content: {
    position: 'absolute',
    right: '15%',
    top: '25%',
    alignItems: 'center'
  },
  qrContainer: {
    marginTop: '10%',
    backgroundColor: 'black',
    width: '90%',
    height: '100%',
    borderRadius: 10,
    zIndex: 3
  },
  qr: {
    backgroundColor: 'white',
    flex: 1,
    borderRadius: 10,
    margin: '5%',
    padding: '5%',
    alignItems: 'center'
  },
  text: {
    fontFamily: 'Quicksand',
    textAlign: 'center',
    fontSize: 40
  },
  image: {
    flex: 1,
    borderRadius: 20
  },
  close: {
    position: 'absolute',
    top: '2%',
    right: '2%'
  }
})