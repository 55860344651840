import { useRef, useState, useEffect } from 'react';
import { StyleSheet, View, Text, ActivityIndicator } from 'react-native'
import { Button, AirbnbRating, Rating, Divider, Icon } from "react-native-elements"
import { logEvent } from "firebase/analytics"

import { getTour, analytics } from "../helpers/fbHelper"

const Review = ({ route, navigation }) => {
  const url = new URLSearchParams(window.location.search);
  const id = url.get('tourId') ?? 'QMqjalo7V3BXT8AefKgK';
  const tour = getTour(id)
  const [ratingsVisible,setVisible] = useState(false)
  const [overall,setOverall] = useState(null)
  const [value,setValue] = useState(null)
  const [personal,setPersonal] = useState(null)
  const [feasible,setFeasible] = useState(null)

  if(tour.isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large"/>
      </View>
    );
  }

  const snapshot = tour.data
  const { 
    Name: name, 
    Description: description, 
    CoverImages: coverImages,
    Guide: guide,
    guideProfilePic,
    rating,
    Stops: stops,
    Owner: owner
  } = snapshot.data() ?? ''

  const submitRating = (skip=false) => {
    const completed = navigation.getParam("completed",true);
    const stopId = navigation.getParam("stopId",stops.length);
    if(!skip) {
      logEvent(analytics, "experience_rating", {
        tour_id: id,
        isGuide: false,
        personality: personal,
        value: value,
        feasibility: feasible,
        overall: overall,
        stopId: stopId ?? stops.length,
        completed: completed ?? true,
      });
    }
    var guideUrl = 'https://freeguides.com/profile/' + owner
    window.location.href = guideUrl
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>REVIEW TOUR</Text>
      <Header name={name} description={description} coverImage={coverImages[0]} rating={rating}/>
        <View style={styles.overallRatingContainer}>
          <View style={styles.overallRatingImageBox}>
            <img
              style={styles.overallRatingImage}
              src={guideProfilePic ?? ""}
            />
          </View>
          <Text style={styles.subtitle}>Overall Review</Text>
          <AirbnbRating
            count={5}
            minValue={1}
            showRating={false}
            onFinishRating={(rating) => {
              setVisible(true)
              setOverall(rating)
            }}
            defaultRating={0}
            size={30}
            starContainerStyle={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "stretch",
            }}
          />
        </View>
        <Button
          icon={<Icon name={!ratingsVisible ? "angle-down" : "angle-up"} type="font-awesome" color="#868E9B" />}
          iconPosition="left"
          title={!ratingsVisible ? "  Detailed review" : "  Close Detailed review"}
          titleStyle={[
            styles.buttonText,
            { color: "#868E9B", fontFamily: "quicksand_bold", fontSize: 12 },
          ]}
          containerStyle={styles.buttonContainer}
          buttonStyle={[styles.buttonStyle, { backgroundColor: "transparent" }]}
          onPress={() => setVisible(!ratingsVisible)}>
        </Button>
        {
          ratingsVisible && 
          <>
            <ExtraRating
              text="Value"
              desc="Did you find out anything new or get anything special along the tour?"
              onUpdate={setValue}
            />
            <ExtraRating
              text="Personality"
              desc="Was there a connection with the guide?"
              onUpdate={setPersonal}
            />
            <ExtraRating
              text="Feasibility"
              desc="Was the tour achievable, could you complete it? "
              onUpdate={setFeasible}
            />
          </>
        }
        <Button
          title="Submit"
          titleStyle={styles.buttonText}
          disabled={!overall}
          containerStyle={styles.buttonContainer}
          buttonStyle={styles.buttonStyle}
          onPress={() => submitRating()}
        />
        <Button
          title="Skip"
          type="clear"
          titleStyle={[styles.buttonText, { color: "#868E9B", fontSize: 12 }]}
          containerStyle={styles.buttonContainer}
          buttonStyle={[styles.buttonStyle, { backgroundColor: "transparent" }]}
          onPress={() => submitRating(true)}
        />
    </View>
  )
}

const Header = ({name='', description='', rating=null, coverImage=''}) => {

  return (
    <View style={styles.headerContainer}>
      <View style={styles.headerBox}>
        <img style={styles.image} src={coverImage} />
      </View>
      <View style={styles.headerBox}>
        <View style={styles.headerContentContainer}>
          {
            rating && 
              <View style={styles.headerRatingBox}>
                <img src={require("../../assets/yellow_star.png")} style={styles.yellowStar} /> 
                <Text style={styles.ratingText}>{rating?.toFixed(1) ?? 'n/a'}</Text>
              </View>
          }
          <Text numberOfLines={2} style={styles.subtitle}>
            {name}
          </Text>

          <Text numberOfLines={7} style={styles.text}>
            {description}
          </Text>
        </View>
      </View>
    </View>
  )
}

const ExtraRating = ({ text = "", desc = "", onUpdate = () => null }) => {
  return (
    <View style={styles.ratingContainer}>
      <Text style={styles.subtitle}>{text}</Text>
      <Text style={styles.text}>{desc}</Text>

      <View style={styles.ratingAlign}>
        <AirbnbRating
          count={5}
          minValue={1}
          showRating={false}
          onFinishRating={onUpdate}
          defaultRating={0}
          size={30}
          starContainerStyle={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "stretch",
          }}
        />
      </View>
      <Divider />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8FA",
    paddingHorizontal: 30,
    paddingTop: 25,
  },
  title: {
    marginTop: 15,
    fontSize: 16,
    fontFamily: "quicksand_bold",
    fontStyle: "normal",
    letterSpacing: 1.5,
    textAlign: "center",
    color: "#868E9B",
  },

  headerContainer: {
    marginTop: 20,
    height: window.height * 0.35,
    width: "100%",
    flexDirection: "row",
  },
  headerBox: {
    width: "50%",
    height: "100%",
  },
  image: {
    width: "100%",
    aspectRatio: 2 / 3,
    borderRadius: 15,
  },
  headerContentContainer: {
    height: "100%",
    paddingTop: 25,
    paddingHorizontal: 23,
  },
  headerRatingBox: {
    width: 40,
    height: 20,
    backgroundColor: "#1F8FFF",
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  yellowStar: {
    height: 9,
    width: 9,
  },
  subtitle: {
    marginTop: 10,
    fontSize: 16,
    fontFamily: "quicksand_bold",
    // fontWeight: "700",
    color: "#141414",
    letterSpacing: -0.2,
  },
  text: {
    marginTop: 10,
    fontSize: 13,
    fontFamily: "quicksand_medium",
    fontWeight: "500",
    color: "#868E9B",
  },
  ratingText: {
    fontFamily: 'quicksand_medium',
    color: 'white'
  },
  reviewContainer: {
    height: "100%",
    width: "100%",
  },
  overallRatingContainer: {
    marginTop: 50,
    width: "97%",
    alignSelf: "center",
    height: 120,
    backgroundColor: "white",
    borderRadius: 15,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",

    shadowColor: "rgba(36, 61, 94, 0.03)",
    shadowOffset: {
      height: 5,
      width: 5,
    },
    shadowOpacity: 1,
    shadowRadius: 10,
  },
  overallRatingImageBox: {
    height: 45,
    width: 45,
    borderRadius: 22.5,
    marginTop: -25,
    zIndex: 1,
  },
  overallRatingImage: {
    height: 45,
    width: 45,
    borderRadius: 22.5,
  },
  ratingContainer: {
    height: 140,
    marginTop: 25,
    width: "97%",
    alignSelf: "center",
    justifyContent: 'space-evenly'
  },
  ratingAlign: {
    marginTop: 8,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  buttonContainer: {
    width: "99%",
    height: 40,
    marginTop: 10,
    alignSelf: "center",
  },
  buttonStyle: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1F8FFF",
  },
  buttonText: {
    fontSize: 16,
    fontFamily: "quicksand_semiBold",
    letterSpacing: -0.2,
    color: "white",
  },
});

export default Review