  import React,{ useState, useEffect } from 'react';
import { StyleSheet, View, Text, Button, ActivityIndicator, FlatList, Modal, ImageBackground } from 'react-native';
import { logEvent } from "firebase/analytics"
import { analytics } from '../../helpers/fbHelper'
import { Icon } from 'react-native-elements'
import QRCode from "react-qr-code";
import coupon from "../../../assets/bogof.png"
import coupon2 from "../../../assets/discount.png"
import coupon3 from "../../../assets/sample.png"
import coupon4 from "../../../assets/special.png"
import coupon5 from "../../../assets/custom.png"
import popup from "../../../assets/popup.svg"
import popup2 from "../../../assets/popup_orange.svg"
import popup3 from "../../../assets/popup_blue.svg"
import popup4 from "../../../assets/popup_pink.svg"
import popup5 from "../../../assets/popup_purple.svg"

const Referral = (props) => {
  const { index, coupons } = props
  const [modalVisible, setModalVisible] = useState(false);
  const [currentCoupon,setCoupon] = useState(null)
  var json = window.location + '&stopId=' + index + '&ref=true' 

  const parseTitle = (top = false,title = "") => {
    var result = ''
    if(title !== "") {
      var arr = title.split(' ')
      if(top) {
        result = arr[0]
      } else {
        if(arr.length > 0) {
          arr.shift()
          result = arr.join(' ')
        }
      } 
    } else {
      result = top ? 'CUSTOM' : 'COUPON'
    }
    return result
  }

  const renderCoupon = ({item,index}) => {
    const { type, discount, title, description, id } = item
    var subtitle = item.subtitle ?? ""

    var options = { 
      bogof: { src: coupon, popupSrc: popup, colour: "#60BE8C", topText: 'BUY 1', bottomText: 'GET 1', subtitle: '2 for 1', description: "With each purchase receive a second for free"}, 
      discount: { src: coupon2, popupSrc: popup2, colour: "#EFAD17", topText: discount + '%', bottomText: 'OFF', subtitle: discount + '% off any purchase coupon', description: "Enjoy a substantial " + discount + '% discount' }, 
      sample: { src: coupon3, popupSrc: popup3, colour: "#1F8FFF", topText: 'FREE', bottomText: 'SAMPLE', subtitle: 'Free sample with this coupon', description: 'Try before you buy with a free sample'}, 
      special: { src: coupon4, popupSrc: popup4, colour: "#D4355B", topText: 'SECRET', bottomText: 'MENU ITEM', subtitle: subtitle, description: description },
      custom: { src: coupon5, popupSrc: popup5, colour: "#5C42E3", topText: parseTitle(true,title) , bottomText: parseTitle(false,title), subtitle: subtitle, description: description } 
    }

    const { src, colour, topText, sideText, bottomText } = options[type]
    subtitle = options[type].subtitle

    return (
      <div onClick={() => {
          setModalVisible(true)
          logEvent(analytics, "press_coupon", { tourId: id, stopId: index, ...item });
          setCoupon({...options[type], type: type, refId: id})
        }} 
        style={{display: 'flex', height: 110}}>
        <img src={src} style={{height: 120, width: 'auto', alignSelf: 'center'}}/> 
        <div style={styles.coupon}>
          <span style={{...styles.text, width: 100, lineHeight: '70%', fontSize: 22, paddingRight: 10}}>
            {topText}<br/>
            <span style={{fontSize: 14}}>
              {bottomText}
            </span>
          </span> 
          <div style={{borderLeft: '2px black dashed', height: 70}}/>
          <div style={{alignItems: 'center', fontSize: 12, paddingLeft: 20, paddingRight: 10,  fontFamily: 'quicksand_bold', width: '90%'}}>
            {subtitle}
            <div style={{...styles.redeem, backgroundColor: colour}}>
              <b style={{color: 'white', fontSize: 14, fontFamily: 'Quicksand'}}>Redeem</b>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const couponModal = () => {
    if (currentCoupon === null) return
    const { src, popupSrc, colour, topText, sideText, bottomText, subtitle, description, refId } = currentCoupon

    return(
      <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)', paddingBottom: '50%'}}>
        <ImageBackground source={popupSrc} resizeMode="contain" style={styles.popup}>
          <div style={{alignSelf: 'flex-end', marginTop: '40%'}}>
            <Icon onPress={() => setModalVisible(false)} size={20} color="white" name="close" type="antdesign" />
          </div>
          <div style={styles.top}>
            <span style={{...styles.text, fontSize: 35, color: 'white'}}>
              {topText + '\n' + bottomText}
            </span>
            <div style={styles.description}>
              {description}
            </div>
          </div>
          <div style={{...styles.line, borderTop: '3px white dashed' }}/>
          <div style={styles.bottomText}>
            <span style={{...styles.text, fontSize: 12, color: 'white'}}>
              Scan by an employee for redemption
            </span>
          </div>
          <div style={styles.qrContainer}>
            <QRCode id="qrcode" value={json + '&refId=' + refId } size={200}/>
          </div>
        </ImageBackground>
      </View>
    )
  }

  var validCoupons = coupons?.content ?? []
  validCoupons = validCoupons.filter(x => x.active === true)

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {
        validCoupons.length > 0 && (
          <Text style={{fontFamily: 'quicksand_bold', fontSize: 14, marginBottom: 7}} >My Coupons</Text>
        )
      }
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {setModalVisible(!modalVisible)}}> 
        {
          couponModal() 
        }
      </Modal>
      <FlatList
        data={validCoupons}
        horizontal
        renderItem={renderCoupon}
      />
      {
        /*
      <span style={styles.text}>
        {value.title && !value.isDigital ? value.title : ""}
      </span>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
        {
          !value.isDigital ? 
            <QRCode id="qrcode" value={json} size={200}/> :
            <a
              style={{...styles.linkContainer, backgroundColor: 'orange'}}
              href={value.content}
              target="_blank"
            >
              <span style={styles.text}>
                {value.title ? value.title : ""}
              </span>
            </a>
        }
      </div>
      <span style={{texAlign: 'center', marginLeft: '5%'}} >
        {value.description ? value.description : ""}
      </span>
        */
      }
    </div>
  )
}

export default Referral

const styles = StyleSheet.create({
  linkContainer: {
    marginTop: 10,
    marginBottom: 10,
    width: "85%",
    minHeight: 40,
    marginLeft: "7.5%",
    marginRight: "7.5%",
    backgroundColor: "#60be83",
    color: 'black',
    borderRadius: 5,
    minHeight: 40,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textDecorationLine: "none",
    paddingHorizontal: 20,
  },
  qrContainer: {
    backgroundColor: 'white', 
    display: 'flex', 
    alignItems: 'center' , 
    height: '90%', 
    justifyContent: 'center', 
    width: 260, 
    borderRadius: 20, 
    boxShadow: '0px 10px 10px gray', 
    alignSelf: 'center' 
  },
  text: {
    fontSize: 20,
    textDecorationLine: "none",
    textAlign: "center",
    fontFamily: 'quicksand_bold'
  },
  subtitle: {
    fontSize: 15,
    textDecorationLine: "none",
    textAlign: "left",
    fontFamily: 'quicksand_bold',
    width: '85%'
  },
  coupon: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    position: 'absolute', 
    top: 20, 
    left: 20,
    paddingLeft: 5, 
    paddingRight: 15
  },
  popup: {
    height: 700, 
    width: 350, 
    alignSelf: 'center', 
    marginTop: '15%', 
    backgroundSize: 'contain', 
    backgroundRepeat: 'no-repeat',
    paddingLeft: '15%',
    paddingRight: '15%',
    textAlign: 'center',
    //justifyContent: 'space-evenly',
    //paddingBottom: '40%',
    paddingTop: '15%',
  },
  top: {
    height: '60%', 
    display: 'flex', 
    justifyContent: 'space-evenly', 
    flexDirection: 'column'
  },
  line: {
    width: '65%', 
    position: 'fixed', 
    top: '45%', 
    alignSelf: 'center'
  },
  description: {
    fontSize: 14, 
    alignItems: 'center', 
    display: 'flex', 
    color: 'white', 
    fontFamily: 'quicksand_bold', 
    width: '100%'
  },
  bottomText: {
    height: '20%', 
    marginTop: '10%', 
    display: 'flex', 
    justifyContent: 'center', 
    flexDirection: 'column'
  },
  redeem: {
    backgroundColor: '#1F8FFF', 
    borderRadius: 5, 
    width: 90, 
    marginTop: 10, 
    height: '30%', 
    textAlign: 'center',
    padding: 3
  }
});
