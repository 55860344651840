import React, { useState, useRef, useEffect } from "react";
import { Platform, ActivityIndicator, Text, View, StyleSheet, Dimensions } from "react-native";
import { Button, Icon } from "react-native-elements";
import { ttsToken, openAIToken } from "../../constants/constants";

const { height, width } = Dimensions.get("window");

const TextToSpeech = (props) => {
  var { stop, introStop, persona } = props
  var [visible,setVisible] = useState(true)
  var [loading,setLoading] = useState(false)
  var [isPlaying,setIsPlaying] = useState(false)
  var [duration, setDuration ] = useState(0)
  var audio = useRef(null)

  useEffect(() => {
    checkForCustomTTS()
    return async () => {
      setLoading(true)
      setIsPlaying(false)
      if(audio.current) {
        audio.current.src = ""
        audio.current = null
      }
      setLoading(false)
    }
  }, [stop])

  const checkForCustomTTS = () => {
    var file = null
    if(introStop) {
      file = persona.audio ?? (persona.ttsFile ?? null)
    } else {
      var ttsForStop = stop.media.filter(val => val.ttsForStop ?? false)
      file = ttsForStop.length > 0 ? ttsForStop[0].content : (stop.ttsFile ?? null)
    } 

    if(file) {
      var tts = new Audio(file)
      audio.current = tts
      var playbackUpdate = null
      tts.addEventListener("loadedmetadata", () => {
        playbackUpdate = setInterval(() => {
          setDuration(tts.duration - tts.currentTime)
        }, 10)
      })

      tts.addEventListener("abort", () => {
        setIsPlaying(false)
        clearInterval(playbackUpdate)
      })
    }
  }

  var text = ""

  if(!introStop) {
    for(var m of stop.media) {
      if(m.type === 'text') {
        var content = m.content.replace(/[/*|_]+(.*)[/*]+/g,"$1")
        content = content.replace(/[#|\*]+\s*(.*)/g,"$1.")
        text += content
      }
    }     
  } else {
    text = persona?.text ?? ""
  }

  const onPress = async () => {
    pauseAudioFiles()
    if(audio.current) {
      if(!isPlaying)  {
        audio.current.play()
      } else {
        audio.current.pause()
      }
     } else {
      setLoading(true)
      await serverTTS('google')
     }
     setIsPlaying(!isPlaying)
  }

  const replay = async () => {
    if(audio.current) {
      await audio.current.load()
      audio.current.play()
      setIsPlaying(true)
    }
  }

  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const pauseAudioFiles = () => {
    var audios = document.getElementsByTagName('audio')
    for(let i = 0; i < audios.length; i++) {
      audios[i].pause()
    }
  }

  const serverTTS = async (service='google') => {  

    //const url = "https://tts-k3ptrqk3da-uc.a.run.app" 
    var url = "", headers = {}, body = ""
    var headers = {'Content-Type': 'application/json' }
    switch (service) {
      case 'google':
        url = 'https://texttospeech.googleapis.com/v1/text:synthesize?key=' + ttsToken
        body = JSON.stringify({
          input: {
            text: text
          },
          voice: { 
            name: 'en-GB-Wavenet-F',
            languageCode: 'en-gb'
          },
          audioConfig: {
            audioEncoding: 'MP3'
          }
        })
        break
      case 'openai': 
        url = 'https://api.openai.com/v1/audio/speech'
        headers = {
          ...headers,
          Authorization: 'Bearer ' + openAIToken
        }
        body = JSON.stringify({
          input: text,
          voice: 'nova',
          model: 'tts-1'
        })
        break 
    }

    await fetch(url,{
      headers: {
        "Content-Type": "application/json",
        //"Authorization": "Bearer " + openAIToken
      },
      method: 'post',
      body: body
    }).then(async response => {
      var blob = service === 'google'? await response.json() : await response.blob()
      console.log(blob)
      if(response.ok) {
        pauseAudioFiles()
        var tts = new Audio(service === 'google' ? "data:audio/mp3;base64," + blob.audioContent : URl.createObjectURL(blob))
        var playbackUpdate = null 

        tts.addEventListener("loadedmetadata", () => {
          playbackUpdate = setInterval(() => {
            setDuration(tts.duration - tts.currentTime)
          }, 10)
        })

        tts.addEventListener("abort", () => {
          setIsPlaying(false)
          clearInterval(playbackUpdate)
        })

        audio.current = tts
        tts.play()
      } else {
        console.log('error => ' + blob.error.message)
      }
      setLoading(false)
    })
  }

  const getMMSSFromMillis = () => {
    
    if(!audio.current || !duration) {
      return ":"
    }

    const totalSeconds = duration;
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor(totalSeconds / 60);

    const padWithZero = (number) => {
      const string = number.toString();
      if (number < 10) {
        return "0" + string;
      }
      return string;
    };
    return padWithZero(minutes) + ":" + padWithZero(seconds);
  }

  return (
    <View style={styles.container}>
      { loading ? <ActivityIndicator size="large" color="gray" animating={true} /> : 
        <> 
          <Icon onPress={onPress} size={30} color='black'
            name={isPlaying ? "pause" : "controller-play"}
            type={isPlaying ? "ionicons" : "entypo"}
          />
          <Text style={styles.text}>{getMMSSFromMillis()}</Text>
          <Icon onPress={replay} size={20} color='black' name="replay" type="material-icons" />
        </>
      }  
    </View>
  )
}

export default TextToSpeech

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    position: 'absolute',
    alignSelf: 'flex-end',
    justifyContent: 'space-evenly',
    paddingHorizontal: 10,
    alignItems: 'center',
    top: height * 0.5,
    left: width * 0.68,
    backgroundColor: 'white',
    height: 45,
    width: '32%',
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
    shadowColor: "#000",
    shadowOffset: { width: 7, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: 15,
    elevation: 5
  },
  text: {
    fontFamily: 'quicksand_bold'
  }
});
