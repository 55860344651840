import React from "react";
import { Dimensions, Image, View } from "react-native";
import { createSwitchNavigator, createAppContainer } from "react-navigation";

import * as Screens from "../screens";


const getInitalUrl = () => {
  const url = new URLSearchParams(window.location.search);
  var isReferral = url.get('ref') ?? false
  var isPlace = url.get('placeId') ?? false
  if(isReferral === 'true') {
    return 'Referral'    
  } else if(isPlace) {
    return 'Place'
  } else {
    return 'During'
  }
}


// ROOT NAVIGATOR
const RootNavigator = createSwitchNavigator(
  {
    During: { screen: Screens.DuringTour },
    Review: { screen: Screens.Review },
    Referral: { screen: Screens.Referral },
    Test: { screen: Screens.Test },
    Place: { screen: Screens.Place }
  },{
    initialRouteName: getInitalUrl(),
    //backBehavior: 'order'
  }
);

export default createAppContainer(RootNavigator);
