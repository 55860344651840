import { useCallback, useEffect } from 'react'
import { StyleSheet, Text, View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import RootNavigator from "./src/navigation/rootNavigator";
import { initializeApp } from 'firebase/app';
import { useFonts } from 'expo-font';
import { QueryClientProvider, QueryClient } from 'react-query'
import './src/styles/markdown-styles.module.css'
import { MenuProvider } from 'react-native-popup-menu';
import './src/constants/clarity.js'
import {Helmet} from "react-helmet";

export default function App() {
  const [fontsLoaded] = useFonts({
    quicksand_reg: require("./assets/fonts/Quicksand-Regular.ttf"),
    quicksand_medium: require("./assets/fonts/Quicksand-Medium.ttf"),
    quicksand_semiBold: require("./assets/fonts/Quicksand-SemiBold.ttf"),
    quicksand_bold: require("./assets/fonts/Quicksand-Bold.ttf"),
    OpenSans: require("./assets/fonts/OpenSans-Regular.ttf"),
    OpenSans_Bold: require("./assets/fonts/OpenSans-Bold.ttf"),
  })

  const queryClient = new QueryClient()

  if(!fontsLoaded) return null

  return (
    <MenuProvider>
      <Helmet>
        <script async defer src="https://widget.getyourguide.com/dist/pa.umd.production.min.js" data-gyg-partner-id="KSIQTMD"></script>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <RootNavigator/>  
      </QueryClientProvider>
    </MenuProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rootNavigator: { 
    backgroundColor: 'transparent'
  }
});
