import React,{ useCallback, useMemo, useRef, useState, useEffect, useContext } from 'react';
import { StyleSheet, View, Text, Button, ActivityIndicator, TouchableOpacity } from 'react-native';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { Marker } from '@react-google-maps/api';
import { Icon, LinearProgress } from 'react-native-elements'
import { NavigationActions } from "react-navigation";
import { logEvent } from "firebase/analytics"
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import mapboxgl from '!mapbox-gl';
import {createComponent} from '@lit-labs/react';

import TextToSpeech from './utils/tts'
import Referral from './utils/referral'
import { SharedIndex } from "../screens/duringTour"
import { analytics } from '../helpers/fbHelper'
import { PlaceOverview as PlaceOverviewWC } from './place_overview/place_overview.js';

const PlaceOverview = createComponent({
  tagName: 'gmpx-place-overview',
  elementClass: PlaceOverviewWC,
  react: React,
});

export const getBestImageUri = (uri, shouldResize = true) => {
  // console.log("Raw uri: ", rawUri);

  var regex = uri.match(/(assets|accounts).*?\?/g);
  if (!regex) {
    // console.warn("Not matchd", rawUri);
    return uri;
  }

  if (shouldResize) {
    // |\.JPG
    var splitUri = uri.split(/(\.png|\.webp|\.jpg|\.jpeg|\.gif|\.avif)/);

    if (
      splitUri.length > 0 &&
      (splitUri[1] === ".png" ||
        splitUri[1] === ".jpg" ||
        // splitUri[1] === ".JPG" ||
        splitUri[1] === ".jpeg")
    ) {
      // if (version) {
      uri = splitUri[0] + "_resized.jpeg" + splitUri.slice(2).join("");
      // } else {
      //   uri = splitUri[0] + splitUri[1];
      // }
    }
  }
  return uri;
};



const MediaSheet = (props) => {
  const { stops, id, map, finishTour, name, persona, setRef, goBack } = props
  const minIndex = persona ? -1 : 0
  const [index, setIndex] = useState(minIndex);
  const stop = stops[index] ?? { media: [], address: '', location: '' }
  var markers = useRef(null)
  var audioRefs = useRef([])

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      console.log('leaving tour!')
      //logEvent(analytics, "finish_tour_press", { tourId: id, selfGuided: false, isGuide: false, stopId: index, completed: false });
    }) 
  }, [])

  useEffect(() => {
    markers.current = []
    stops.map((elem,stopIndex) => {
      if(map.current) {
        const { longitude, latitude } = elem.location
        const el = document.createElement('div'); 
        el.className = 'marker'
        el.innerHTML += (stopIndex + 1).toString()
        markers.current.push(el)
        const marker = new mapboxgl.Marker({
          element: el
        }).setLngLat([longitude, latitude]).addTo(map.current)
        
        marker.getElement().addEventListener('click', (e) => {
          setIndex(stopIndex)
          markers.current.map((m,i) => {
            m.style.backgroundColor = i === stopIndex ? '#ffa53e' : 'white'
            m.style.color = i === stopIndex ? "white" : "#1F8FFF";
            m.style.zIndex = i === stopIndex ? (stops.length + 1) : (stops.length - i)
          })
        })
      }
    })  
    markers.current[0].style.backgroundColor = '#ffa53e'
    markers.current[0].style.color = 'white'
    markers.current[0].style.zIndex = stops.length - 1
    map.current.panBy([0,250])
  }, [map])

  useEffect(() => {

    audioRefs.current = []

  }, [index])

  const changeStop = (next=true,stops) => {

    var newIndex = index
    var eventParams = { 
      tourId: id,
      selfGuided: true,
      isGuide: false,
      stopId: index,
    }

    const max = stops.length - 1 
    if(next) {
      if(index < max) {
        logEvent(analytics, "next_press_during_tour", eventParams);
        newIndex = newIndex + 1
      } else {
        logEvent(analytics, "finish_tour_press", eventParams);
        finishTour()
      }
    } else {
      logEvent(analytics, "back_press_during_tour", eventParams);
      newIndex = index > minIndex ? index - 1 : minIndex
    }
    var stop = stops[newIndex]
    markers.current.map((m,i) => {
      m.style.backgroundColor = i === newIndex ? '#ffa53e' : 'white'
      m.style.color = i === newIndex ? "white" : "#1F8FFF";
      m.style.zIndex = i === newIndex ? (stops.length + 1) : (stops.length - i)
    })

    if(index !== newIndex) {
      setTimeout(() => {
        map.current.easeTo({ center: [stop.location.longitude, stop.location.latitude], 
          offset: [0,-250], duration: 1000, zoom: 17
        })
      }, 1)
    }
    setIndex(newIndex)
    scrollToTop()
  }

  const scrollToTop = () => {
    //retrieve the inner div scroll element of the bottom sheet, because there is no 
    //id we have to query the document nodes for it
    const dom = document.querySelector("body reach-portal div div div[data-rsbs-scroll='true']")
    if(dom) {
      dom.scrollTo(0,0)
    }
  }

  const header = 
    <View>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingVertical: '2%'}}>
        <View style={{width: '75%'}}>
          <Text style={styles.title}>{index >= 0 ? stop.address : 'Guide Introduction'}</Text>
          {
            //<Text style={{...styles.title, color: 'lightgray' }}>{name}</Text>
          }
        </View>
        <Icon onPress={() => changeStop(false,stops)} size={32} 
              color="#B0B9C8" name="left" type="antdesign" />
        <Icon onPress={() => changeStop(true,stops)} size={32} 
              color={index < (stops.length - 1) ? "#B0B9C8" : '#1F8FFF'} name="right" type="antdesign" />
      </View>
      <LinearProgress
        style={{ borderRadius: 4 }}
        trackColor="#E9E9F0"
        color="black"
        variant="determinate"
        value={
          index === -1
            ? 0.5 / stops.length
            : index === 0
            ? index + 1 / stops.length
            : index / (stops.length - 1)
        }
      />
      {index >= minIndex ? <TextToSpeech persona={persona} stop={stop} introStop={index < 0} /> : null}
    </View>

  const getPersonaFile = () => {
    if(persona?.src) {
      if(persona?.type?.includes('image')) {
        return (
          <img src={persona.src} style={styles.image}/> 
        )
      } else {
        return (
          <video width={"100%"} height={"100%"} controls style={styles.video} > 
            <source src={persona.src} />
          </video>
        )
      }
    }
  }

  const renderMedia = ({ media=[] }, persona=null) => {
    if(index < 0 && persona) {
      return (
        <View style={{...styles.sheetContainer }}>
          {getPersonaFile()}
          <View style={{ paddingHorizontal: 10 }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{persona.text}</ReactMarkdown>
          </View>
        </View>
      )
    }

    return (
      <View style={{...styles.sheetContainer, height: media.length > 0 ? 'auto' : 600 }}>
        {
          media.map(({content, type, title, ttsForStop=false}, i) => {
            if(type === 'text') {
              return (
                <View  key={i} style={{ paddingHorizontal: 10 }}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
                </View>
              )
            } else if (type === 'img') {
              return (
                <img key={i + ';' + Date.now()} src={content} style={{width: 'auto', maxHeight: 600, paddingBottom: 10}} />
              )
            } else if (type === 'video') {
              return (
                <video ref={e => e !== null && e.load()}
                  key={i}
                  width={"100%"}
                  height={"100%"}
                  controls
                  style={styles.video}>
                  <source src={content} />
                </video>
              )
            } else if (type === 'audio' && !ttsForStop) {
              return (
                <audio ref={(e) => {
                  if(e !== null) {
                    e.load()
                    audioRefs.current.push(e)
                  }
                }} style={styles.video} key={i} controls > 
                  <source src={content} />
                </audio>
              )
            } else if (type === 'link') {
              return (
                <a
                  style={styles.linkContainer}
                  href={content}
                  target="_blank">
                  <Text style={styles.linkText}>
                    {title ?? content}
                  </Text>
                </a>
              )
            } else if (type === 'place') {
              return (
                <PlaceOverview
                  size="x-large"
                  //place="ChIJfTxskB6vEmsRXPSIwVlleUA"
                  place={content.place_id}
                  google-logo-already-displayed
                />
              )
            } else if (type === 'referral') {
              return ( 
                <View style={{flex: 1, paddingHorizontal: 10}}>
                  <Referral index={index} coupons={media[i]} />
                </View>
              )
            }
          })
        }     
      </View>
    )
  }

  const renderMarker = ({ location },stopIndex) => {
    var background = index === stopIndex ? 
     require("../../assets/stop_orange.png") : 
     require("../../assets/stop_blank.png");
    var text = index === stopIndex ? "white" : "#1F8FFF";

    return ( 
      <div 
        key={'marker-'+ stopIndex.toString()}
        label={{text: (stopIndex + 1).toString(), color: text, fontFamily: 'quicksand_bold'}}
        position={{
          lat: location.latitude,
          lng: location.longitude
        }}
        options={{
           icon: {
            url: background,
            scaledSize: { width: 25, height: 25 },            
          }
        }}
        onclick={() => setIndex(stopIndex)}
      />
    )
  }


  return (
    <>
      <div style={{ position: "absolute", top: '5%', left: "2%"}}>
        <Icon onPress={() => goBack(index)} raised size={15} color="#868E9B" name="close" type="antdesign" />
      </div>
      <BottomSheet 
        ref={(e) => setRef(e)}
        blocking={false}
        defaultSnap={({maxHeight}) => maxHeight / 4}
        snapPoints={({ maxHeight }) => [
          maxHeight - maxHeight / 10,
          maxHeight * 0.65,
          maxHeight / 4,
        ]}
        open
        expandOnContentDrag
        header={header}>  
        {renderMedia(stop,persona)}
        <div style={{display: 'flex', paddingLeft: 10, paddingRight: 10, justifyContent: 'space-between'}}>
          <TouchableOpacity onPress={() => window.open("mailto:help@freeguides.com.au")}>
            <p style={styles.bottomText}>Report a Problem</p>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => window.open("https://www.freeguides.com/hotels?utm_source=tourapp&utm_medium=copyright%20on%20tour ") }>
            <p style={styles.bottomText}>© FreeGuides {new Date().getFullYear()}</p>
          </TouchableOpacity>
        </div>
      </BottomSheet>
    </>
  )
}

export default MediaSheet


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center'
  },
  title: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'quicksand_bold',
    fontSize: 17
  },
  sheetContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    height: 300
  },
  video: {
    width: '100%',
    paddingBottom: 10
  },
  linkContainer: {
    marginTop: 10,
    marginBottom: 10,
    width: "85%",
    minHeight: 40,
    marginLeft: "7.5%",
    marginRight: "7.5%",
    backgroundColor: "#60be83",
    borderRadius: 5,
    minHeight: 40,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textDecorationLine: "none",
    paddingHorizontal: 20,
  },
  linkText: {
    color: "white",
    fontSize: 12,
    textDecorationLine: "none",
    textAlign: "center",
    fontFamily: 'quicksand_bold'
  },
  bottomText: {
    fontFamily: 'Quicksand',
    color: 'rgba(0,0,0,0.5)',
    fontSize: 14
  }
});
