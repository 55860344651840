import { useRef, useState, useEffect } from 'react';
import { View } from 'react-native';
import { Icon } from 'react-native-elements'

import mapboxgl from '!mapbox-gl'; 

class ToggleControl extends mapboxgl.GeolocateControl {
  _onSuccess(position) {
      this.map.flyTo({
          center: [position.coords.longitude, position.coords.latitude],
          zoom: 17,
          bearing: 0,
          pitch: 0
      });
  }

  onAdd(map, cs) {
    this.map = map;
    this.container = this._createButton('mapboxgl-ctrl');
    //this.container.className = `mapboxgl-ctrl`;
    this.container.style = {
      //position: 'absolute',
    }
    return this.container;
  }

  _createButton(className) {
    const el = window.document.createElement('button')
    el.className = className;
    el.textContent = 'Use my location';
    el.addEventListener('click', () => {
        this.trigger();
    });
    this._setup = true;
    return el;
  }
}

export default ToggleControl
