import { useRef, useState, useEffect } from 'react';
import { StyleSheet, View, Text, Button, ActivityIndicator } from 'react-native';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { Icon, LinearProgress } from 'react-native-elements'
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm'
import { GoogleMap, LoadScript, CircleF, DirectionsService, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { NavigationActions } from "react-navigation";
import { logEvent } from "firebase/analytics"
import {isMobile} from 'react-device-detect';

import TextToSpeech from '../components/utils/tts'
import DropdownMenu from '../components/duringTourMenu' 
import MediaSheet from "../components/mediaSheet"
import { throttle } from "../components/utils.js"
import ToggleControl from "../components/location"
import DesktopModal from "../components/desktopModal"
import { getTour, analytics } from '../helpers/fbHelper'
import mapboxgl from '!mapbox-gl'; 

import 'react-spring-bottom-sheet/dist/style.css'
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1IjoicmFqLWZyZWVndWlkZXMiLCJhIjoiY2xrdDRmY3RzMDNvNzNmcndoeDZ4aW9rbiJ9.fytIKWCe_2N_pHthxzh8cA';
const API_KEY = "AIzaSyB7fofXXxvV2vcvADFR398kS9BzTu-7usU"

const libraries = ["places","drawing","marker"]

export default function DuringTour({ navigation, screenName }) {
  const [region, setRegion] = useState(null);
  const [rotation,setRotation] = useState(0)
  const [response,setResponse] = useState(null)
  const [isVisible,setVisible] = useState(false)
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const sheetRef = useRef(null)
  const userLoc = useRef(null)
  const userMarker = useRef(null)
  const url = new URLSearchParams(window.location.search);
  const id = url.get('tourId') ??  'QMqjalo7V3BXT8AefKgK'; //'VJAQ4m96wcaCulr1dIfq' 
  const tour = getTour(id)

  useEffect(() => {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setTimeout(function() {
        window.scrollTo(0, 1);
    }, 1);

    logEvent(analytics, "start_tour", {
      tourId: id,
      selfGuided: false,
      isPaid: false
    });

    logEvent(analytics, 'screen_view', {
      firebase_screen: 'DuringTour', 
    });

    return (() => {
      window.removeEventListener("deviceorientation", rotateMarker)
    })

  }, [])


  const onMarkerLoad = (marker,accuracyCircle=false) => {

    if(!accuracyCircle) {
      if (typeof window.DeviceMotionEvent !== "undefined" && 
        typeof window.DeviceMotionEvent.requestPermission === 'function') {
        window.DeviceOrientationEvent.requestPermission().then(permission => {
          if (permission === 'granted') {
            window.addEventListener("deviceorientation", (event) => rotateMarker(marker,event))
          }
        })
      } else {
        window.addEventListener("deviceorientation", (event) => rotateMarker(marker,event))
      }
    } else {
      marker.setOptions({
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 10,
          fillColor: '#4285f3',
          fillOpacity: 1.0,
          strokeWeight: 2,
          strokeColor: '#f8f9fa',
        },
      })
    }

    navigator.geolocation.watchPosition(position => {
      const previousCoordinates = userLoc.current;
      // Update the user location
      userLoc.current = {
        lat: position.coords.latitude, 
        lng: position.coords.longitude
      };
               
      marker.setPosition(userLoc.current)
    }, error => console.log(error), { timeout: 20000, maximumAge: 300, enableHighAccuracy: true });
  };

  const mapLoad = (ref) => {
    map.current = ref 
    setTimeout(() => {
      map.current.panBy(0,250)
    }, 1000)
  }

  const gotoMyLocation = () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      map.current.panTo(parseLatLng(pos.coords))
      map.current.panBy(0,250)
    })
  }

  const rotateMarker = (marker,event) => {
    //setRotation((event.alpha.toFixed(0) * -1))
    marker.setOptions({
      icon: {
        path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
        scale: 5,
        fillColor: '#4285f3',
        fillOpacity: 1.0,
        strokeWeight: 0,
        anchor: {x: 0, y: 5},
        rotation: event.webkitCompassHeading ?? event.alpha * -1,
      },
    })
  }

  const getWaypoints = (stops) => {
    var result = []
    const array = stops.slice(1,stops.length - 1)
    array.map(({location}) => result.push({location: parseLatLng(location)}))
    return result
  }

  const parseLatLng = ({latitude=0, longitude=0},padding=0) => {
    return { 
      lat: parseFloat(latitude) + padding,
      lng: parseFloat(longitude)
    }
  }

  const directionsCallback = (apiResponse) => {
    console.log(apiResponse)
    if (response === null) {
      if (apiResponse.status === 'OK') {
        setResponse(apiResponse)
      } else {
        console.log('response: ', apiResponse)
      }
    }
  }

  const finishTour = () => {
    navigation.navigate({
      routeName: "Review",
      params: { id: id }
    })
  }


  if(!isMobile && !window.location.href.includes("localhost")) {
    return (
      <DesktopModal id={id}/>
    )
  } else if(tour.isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large"/>
      </View>
    );
  }

  const snapshot = tour.data;
  var origin, dest = null
  var waypoints = []
  const { Name: name, Stops: stops, Guide: guide, Owner: owner, persona } = snapshot.data() ?? ''
  if(!response && stops.length > 0) {
    origin = parseLatLng(stops[0].location)
    dest = parseLatLng(stops[stops.length - 1].location)
    waypoints = getWaypoints(stops)
  }

  var stop = stops[0] ?? { media: [] }
  let loc = parseLatLng(stop.location,0)

  const goBack = (stopId) => {
    //var guideUrl = 'https://freeguides.com/profile/' + owner
    //window.location.href = guideUrl
    navigation.navigate({
      routeName: "Review",
      params: { stopId: stopId, completed: false }
    })
  }

  const renderDirections = () => {
    if(!response) {
      return (
        <DirectionsService
          // required
          options={{ // eslint-disable-line react-perf/jsx-no-new-object-as-prop
            origin: origin,
            destination: dest,
            travelMode: 'WALKING',
            waypoints: waypoints
          }}
          // required
          callback={directionsCallback}
        />
      )
    } else {
      return (
        <DirectionsRenderer
          options={{ 
            directions: response,
            polylineOptions: {  strokeWidth: 3, strokeColor: "#1F8FFF" }, 
            preserveViewport: true,
            suppressMarkers: true
          }}
        />
      )
    }
  }

  return (
    <View style={styles.container}>
      <LoadScript version="beta" libraries={libraries} googleMapsApiKey={API_KEY}>
        <Map category={snapshot.data().Category} setMap={(ref) => map.current = ref } sheet={sheetRef} stops={stops} />
        <MediaSheet setRef={(ref) => sheetRef.current = ref} name={name} goBack={goBack}
          persona={JSON.stringify(persona) !== "{}" ? persona : null} 
          finishTour={finishTour} map={map} id={id} stops={stops} />
      </LoadScript>
      <div style={{position: "absolute", left: "83%", top: '5%'}}>
        <DropdownMenu category={snapshot.data().Category} tourId={id} updateMapType={(type) => map.current.setStyle(type) } />
      </div>
    </View>
  );
}

const Map = (props) => {
  const { stops, setMap, sheet, category } = props
  const mapContainerRef = useRef(null);
  const route = useRef(null)
  var loadCounter = 0

  useEffect(() => {
    const { latitude, longitude } = stops[0].location
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: category === 'Other' ? 'mapbox://styles/mapbox/satellite-streets-v12' : 'mapbox://styles/mapbox/streets-v12',
      center: [longitude , latitude],
      zoom: 17
    });

    const toggleControl = new mapboxgl.GeolocateControl({
      fitBoundsOptions: { offset: [0,-100], maxZoom: 17 },
      positionOptions: { timeout: 20000, maximumAge: 300, enableHighAccuracy: true },
      trackUserLocation: true,
      showUserHeading: true   
    })

    const loadRoute = (route=null) => {
      if(route) {
        map.addLayer({
          id: 'route',
          type: 'line',
          source: { type: 'geojson', data: route },
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#3398f9',
            'line-width': 4,
            'line-opacity': 0.95
          }
        })
      }
    }

    map.addControl(toggleControl)
    map.on('load', async () => {
      //toggleControl.trigger()
      toggleControl.trigger()
      route.current = await fetchDirections()
      loadRoute(route.current)
    })

    map.once('zoomend', () => {
      setTimeout(() => {
        map.flyTo({center: [longitude, latitude], duration: 5000,
          offset: [0,-250], zoom: 16, speed: 0.7
        })
        setTimeout(() => sheet.current?.snapTo(400),5500)
      }, 2000)
    });

    map.on('style.load', () => {
      loadRoute(route.current)
    })

    setMap(map)
  }, [])

  const fetchDirections = async () => {
    const url = "https://api.mapbox.com/directions/v5/mapbox/walking/"
    var headers = { "Content-Type": "application/json" }
    var coords = ""
    stops.slice(0,25).map(({location}) => coords += location.longitude + "," + location.latitude + ';' )
    coords = coords.substring(0,coords.length - 1)

    try {
      var response = await fetch(url + coords + '?geometries=geojson&access_token=' + mapboxgl.accessToken, {
        method: "get",
        headers: headers,
      })

      var value = await response.json()
      if(value.routes.length > 0) {
        return {
          type: 'Feature',
          properies: {},
          geometry: { type: 'LineString', coordinates: value.routes[0].geometry.coordinates }
        }
      }

      return null
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <View style={{flex: 1}} className="map-container" ref={mapContainerRef} />
  )
}

const makeGradientColor = (color1, color2, percent) => {
    var newColor = {};
    const makeChannel = (a, b) => {
        return(a + Math.round((b-a)*(percent/100)));
    }
    const makeColorPiece = (num) => {
        num = Math.min(num, 255);   // not more than 255
        num = Math.max(num, 0);     // not less than 0
        var str = num.toString(16);
        if (str.length < 2) {
            str = "0" + str;
        }
        return(str);
    }
    newColor.r = makeChannel(color1.r, color2.r);
    newColor.g = makeChannel(color1.g, color2.g);
    newColor.b = makeChannel(color1.b, color2.b);
    newColor.cssColor = "#" + 
                        makeColorPiece(newColor.r) + 
                        makeColorPiece(newColor.g) + 
                        makeColorPiece(newColor.b);
    return(newColor);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center'
  },
  title: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'quicksand_bold',
    fontSize: 17
  },
  sheetContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    height: 300
  },
  video: {
    width: '100%',
    paddingBottom: 10
  }
});
