/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

// Placeholder for ExtensibleEvent (google3-only)

/**
 * Event emitted when error occurs in an underlying request to the Google Maps
 * API web service.
 *
 * @param error The `Error` object thrown by the Maps JavaScript API.
 */
export class RequestErrorEvent extends Event {
  constructor(readonly error: unknown) {
    super('gmpx-requesterror', {bubbles: false, composed: false});
  }
}
